@import "../../assets/styles/utils";
.index-page{
    .comp-root{
        padding-top: 0;
        height: 100vh;
        overflow-y: hidden;
    }
}

.loading{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-main;
    z-index: 1000;
    text-align: center;
    img{
        margin: 0 auto;
        @include res(margin-bottom, 50px, 20 / 50);
    }
    .t{
        color: #fff;
        font-size: 14px;
        span {
            display: inline-block;
            height: 1em;
            line-height: 1;
            text-align: left;
            vertical-align: -0.25em;/* 属性设置元素的垂直对齐方式。指定为负长度，可以使元素降低 */
            overflow: hidden;
            margin-left: 5px;
        }
        span::before{
            display: block;
            content: '...\A..\A.';
            white-space: pre-wrap;/* 保留空白符序列 */
            animation: span 3s infinite step-start both;
        }
        @keyframes span{
            33%{ transform: translateY(-2em); }
            66%{ transform: translateY(-1em); }
        }
    }
}

.banner {
    position: relative;
    height: 100vh;
    box-sizing: border-box;
    @include res(padding-top,$header-height-base, $header-height-ratio);
    #banner-swiper {
        // @include res(padding-top, 37px,(sm:70px));
        height: 100%;
        width: 100%;
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;

        .swiper-slide {
            overflow: hidden;
            &.swiper-slide-active ,&.swiper-slide-duplicate-active{
                .title {
                    opacity: 1;
                    transform: translateY(-50%);
                }
            }

            b {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-position: center center;
                background-size: cover;
            }
            .title {
                position: absolute;
                width: fit-content;
                text-align: center;
                top: 50%;
                transform: translateY(-40%);
                right: 0;
                left: 0;
                margin: auto;
                z-index: 2;
                transition: all .5s;
                opacity: 0;
                transition-delay: .8s;
                h2 {
                    @include res(font-size,60px ,18 / 60);
                    color: #ffffff;
                    @include res(line-height,1.6)
                }
                p {
                    @include res(margin-top, 175px, 30 / 175);
                    @include res(font-size, 20px, 14 / 20);
                    color: #ffffff;
                }
            }
        }

        .swiper-button-next {
            @include res(display,block,(xs:none));
            @include res(right, 65px, 25 / 65);
            display: flex;
            align-items: center;
            justify-content: center;
            @include res(width, 44px, 30 / 44);
            @include res(height, 44px, 30 / 44);
            background: #fff;
            border-radius: 50%;

            i {
                @include res(font-size, 20px, 16 /20);
                color: #a89cbb;
            }

            &:after {
                content: none;
            }
        }

        .swiper-button-prev {
            @include res(display, block, (xs:none));
            @include res(left, 65px, 25 / 65);
            display: flex;
            align-items: center;
            justify-content: center;
            @include res(width, 44px, 30 / 44);
            @include res(height, 44px, 30 / 44);
            background: #fff;
            border-radius: 50%;

            &:after {
                content: none;
            }

            i {
                @include res(font-size, 20px, 16 /20);
                color: #a89cbb;
                font-weight: bold;
            }
        }
    }
    .yinying {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0,0,0,.2);
        z-index: 1;
        width: 100%;
        height: 100%;
    }

}
.section2 {
    @include res(padding-top, 80px, 0 / 80);
    width: 100%;
    position: relative;
    .icons {
        @include res(display,block,(sm:none));
        position: absolute;
        right: 40px;
        top: 0;
        transform: translateY(-50%);
        z-index: 10;
        img {
            max-width: fit-content;
            width: 100%;
        }
    }
    .container {
        @include res(max-width,920px,(sm:100%));
        position: relative;
        .semicolon {
            @include res(display, block, (sm:none));
            position: absolute;
            top: 0;
            left: 0;
        }
        .content {
            display: none;
            .title {
                @include res(padding-top, 20px, 20 / 20);
                @include res(margin-bottom, 20px, 20 / 20);
            }
            p {
                @include res(font-size, 16px, 14 / 16);
                @include res(line-height, 2, 1.6 / 2);
                color: #000000;
            }
        }
        .pic {
            @include res(margin-top, 80px, 30 / 80);
            display: flex;
            align-items: center;
            justify-content: center;
            a {
                position: relative;
                @include res(margin-right, 68px, 15 / 68);
                .circular {
                    @include res(width, 189px, (xs:80px));
                    @include res(height, 189px, (xs:80px));
                    border-radius: 50%;
                    background: #A89CBB;
                    position: relative;
                }
                &:last-child {
                    margin-right: 0;
                }
                &.active {
                    img {
                        opacity: 1;
                    }
                    .circle {
                        // animation: anim .1s;
                        opacity: 1;
                        transform: translate3d(-50%,-50%,0) scale(1.05);
                    }
                    .circle1 {
                        // animation: anim .3s;
                        opacity: 1;
                        transform: translate3d(-50%,-50%,0) scale(1.1);
                    }
                }
                img {
                    transition: all .3s;
                    opacity: .5;
                    display: block;
                    position: relative;
                    z-index: 2;
                    height: auto;
                    @include res(width,fit-content,(xs:80px));
                }
                p {
                    margin-top: 15px;
                    text-align: center;
                    @include res(font-size, 16px, 14 / 16);
                    font-family: 'Roboto-Medium';
                    color: #000;
                }
                .circle {
                    @include res(width, 100%);
                    @include res(height, 100%);
                    border-radius: 50%;
                    border: 1px solid rgba(57, 57, 57, .5);;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                    opacity: 0;
                    transition: all .3s;
                }
                .circle1 {
                    @include res(width, 100%);
                    @include res(height, 100%);
                    border-radius: 50%;
                    border: 1px solid rgba(57,57,57,.3);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transition: all .3s;
                    opacity: 0;
                    transform: translate3d(-50%, -50%, 0);
                }
            }
        }
    }
    .pic-bg {
        width: 100%;
        @include res(height, 150px, (xs:fit-content));
        background: #EEEEEE;
        position: relative;
        @include res(padding-top, 0, (xs:10px));
        @include res(padding-bottom, 0, (xs:10px));
        @include res(margin-top, 150px, (xs:20px));
        .pic-box {
            @include res(position, absolute, (xs:relative));
            left: 0;
            right: 0;
            margin: auto;
            @include res(transform, translateY(-50%), (xs:none));
            display: flex;
            @include res(justify-content, center, (xs:space-between));
            align-items: center;
            @include res(flex-wrap,nowrap,(xs:wrap));
            div {
                @include res(margin-right, 40px, (xs:0));
                @include res(width, fit-content, (xs:18%));
            }
            img {
                width: 100%;
                display: block;
                height: auto;
                transition: all .3s;
                @include res(margin-top,25px,(xs:0));
                &:hover {
                    @include res(transform, scale(1.1), (xs:scale(1)));
                }
                &:last-child {
                    margin-right: 0;
                    margin-top: 0;
                }
            }
        }
    }
}
.section3 {
    position: relative;
    z-index: 10;
    margin-bottom: -25px;
    width: 100%;
    background: #A89CBA;
    @include res(padding-top, 120px, 20 / 120);
    @include res(padding-bottom, 105px, 15 / 105);
    @include res(padding-left, null, (xs:10px));
    @include res(padding-right, null, (xs:10px));
    h2 {
        width: 100%;
        text-align: center;
        font-family: 'Roboto-Medium';
        @include res(font-size,40px, 18 / 40);
        color: rgba(255, 255, 255, .8);
        @include res(margin-bottom, 25px, 15 / 25);
    }
    h3 {
        width: 100%;
        text-align: center;
        @include res(font-size,18px, 14 / 18);
        color:#fff;
        font-weight: normal;
        @include res(line-height,1.8 , 1.6 / 1.8)
    }
    .box {
        @include res(margin-top, 70px, 20 / 70);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            @include res(margin-right,68px , 10 / 68);
            &:last-child {
                margin-right: 0;
            }
            .pic {
                @include res(width,147px,(xs:90px));
                @include res(height,171px,(xs:104px));
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    height: auto;
                    @include res(width,null,(xs:40px));
                }
            }
            p {
                text-align: center;
                @include res(font-size, 16px, 14 / 16);
                color: #ffffff;
                @include res(margin-top, 20px, 10 / 20);
            }
        }
    }
}
.section4 {
    position: relative;
    .parallux:nth-child(1){
        .text h2{
            @include res(display,null,(md:block));
        }
    }
    .text {
        @include res(padding-top,0,(md:20px));
        @include res(width, 50%, (md:100%));
        @include res(position,absolute,(md:relative));
        position: absolute;
        color: #000;
        height: 100%;
        h2 {
            font-family: 'Roboto-Medium';
            @include res(display,null,(md:none));
            @include res(font-size, 50px, 26 / 50);
            @include res(margin-top, 90px, 30 / 90);
            @include res(margin-left, 94px, 30 / 94);
        }
        p {
            @include res(margin-top, 50px, 20 / 50);
            text-align: center;
            font-family: 'Roboto-Medium';
            @include res(font-size, 26px, 16 / 26);
        }
        img {
            display: block;
            text-align: center;
            margin: auto;
            @include res(margin-top, 70px, 25 / 70);
            max-width: fit-content;
            // @include res(width,100%,(xs:70%));
            @include res(width, 100%, (llg:46%, lg:44%, mmd:36%, md:70%));
        }
        .more {
            bottom: 0;
            width: 100%;
            left: 0;
            background: rgba(0,0,0,.05);
            display: flex;
            align-items: center;
            justify-content: center;
            @include res(margin-top,null,(md:30px));
            @include res(height, 146px,(md:120px,sm:60px));
            @include res(position, absolute,(md:static));
            a {
                position: relative;
                display: block;
                @include res(font-size, 16px, 14 / 16);
                transition: all .3s;
                &:before,
                &:after {
                    content: " ";
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    top: auto;
                    bottom: -5px;
                    height: 2px;
                    background-color: #000;
                    background-color: #000000;
                    opacity: 0.3;
                }
            
                &:after {
                    width: 0;
                    left: auto;
                    right: 0;
                    opacity: 1;
                    transition-duration: 0.3s;
                    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
                    background-color: #E7C4CB;
                }
            
                &:hover {
                    &:after {
                        width: 100%;
                        right: auto;
                        left: 0;
                    }
                }
            }
        }
    }
    .parallux {
        .text {
            &.fix {
                position: fixed;
                top: 0;
                transition: all .5s;
                z-index: 1;
            }
        }
        .parallux-bg {
            @include res(display,block,(md:none));
            @include res(position,fixed);
            @include res(width,50%);
        }
        overflow: hidden;
        // &:nth-child(1) {
        //     .text {
        //         background: #F3CFD6;
        //         &.fix {
        //             z-index: 1;
        //         }
        //     }
        // }
        // &:nth-child(2) {
        //     .text {
        //         background: #E8E1D7;
        //         .more {
        //             a {
        //                 &:after {
        //                     background: #DCD6CC;
        //                 }
        //             }
        //         }
        //         &.fix {
        //         }
        //     }
        // }
        // &:nth-child(3) {
        //     .text {
        //         background: #F3CFD6;
        //     }
        //     &.fix {
        //     }
        // }

    }
}
.section5 {
    .left {
        @include res(width,fit-content,(xs:48%));
    }
    .right {
        @include res(width,fit-content,(xs:48%));
    }
    @include res(margin-left, 65px, 10 / 65);
    @include res(margin-right, 65px, 10 / 65);
    display: flex;
    @include res(justify-content, center, (xs:space-between));
    @include res(padding-top,140px,10 / 140);
    .product {
        display: block;
        position: relative;
        width: fit-content;
        overflow: hidden;
        @include res(margin-bottom, 77px, 10 / 77);
        img {
            transition: all .3s;
            display: block;
            width: 100%;
            max-width: fit-content;
            opacity: .7;
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
            .text {
                span {
                    opacity: 1;
                    &:after {
                        width: 100%;
                    }
                }
            }
        }
        .product-model {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            height: 100%;
            background: #000;
            z-index: -1;
        }
        .text {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            margin: auto;
            text-align: center;
            h2 {
                @include res(font-size, 40px,18 / 40);
                color: #ffffff;
                
            }
            span {
                position: relative;
                @include res(font-size, 16px, 14 / 16);
                transition: all .3s;
                color: #ffffff;
                @include res(display,null,(xs:none));
                @include res(opacity,0,(xs:1));
                @include res(margin-top, 35px, 20 / 35);
                &:after {
                    content: "";
                    display: block;
                    width: 0;
                    height: 1px;
                    background-color: #fff;
                    position: absolute;
                    bottom: -6px;
                    left: 0;
                    transition: all .3s;
                }
            }
        }
    }
    .right {
        @include res(margin-top, 130px,(xs:0));
        @include res(margin-left, 60px, (xs:0));
    }
}
.section6 {
    @include res(margin-top,70px,0 / 70);
    display: flex;
    @include res(flex-wrap,nowrap,(sm:wrap));
    .pic {
        @include res(width,63.23%,(sm:100%));
        img {
            max-width: 100%;
            width: 100%;
            display: block;
        }
    }
    .text {
        width: 100%;
        flex: 1;
        background: #EEEEEE;
        box-sizing: border-box;
        @include res(padding-top,147px,(llg:100px, lg:80px, mmd:40px, md:20px));
        @include res(padding-left, 94px,(llg:70px, lg:50px, mmd:40px, md:20px,xs:10px));
        @include res(padding-bottom, 0, (md:20px));
        @include res(padding-right, 0, (md:20px,xs:10px));
        p {
            @include res(margin-bottom, 50px,(lg:30px,mmd:20px));
            @include res(width, 487px, (sm:90%,xs:100%));
            @include res(font-size,18px , 15 / 18);
            color: #a89cba;
            @include res(line-height,2, 1.6 / 2)
        }
        h2 {
            font-family: 'Roboto-Medium';
            color: #a89cba;
            @include res(font-size, 32px, 20 / 32);
        }
    }
}