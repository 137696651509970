@charset "UTF-8";
.index-page .comp-root {
  padding-top: 0;
  height: 100vh;
  overflow-y: hidden;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #a89cba;
  z-index: 1000;
  text-align: center;
}

.loading img {
  margin: 0 auto;
  margin-bottom: 50px;
}

@media (max-width: 1600px) {
  .loading img {
    margin-bottom: 44px;
  }
}

@media (max-width: 1366px) {
  .loading img {
    margin-bottom: 38px;
  }
}

@media (max-width: 1024px) {
  .loading img {
    margin-bottom: 32px;
  }
}

@media (max-width: 991px) {
  .loading img {
    margin-bottom: 26px;
  }
}

@media (max-width: 767px) {
  .loading img {
    margin-bottom: 20px;
  }
}

.loading .t {
  color: #fff;
  font-size: 14px;
}

.loading .t span {
  display: inline-block;
  height: 1em;
  line-height: 1;
  text-align: left;
  vertical-align: -0.25em;
  /* 属性设置元素的垂直对齐方式。指定为负长度，可以使元素降低 */
  overflow: hidden;
  margin-left: 5px;
}

.loading .t span::before {
  display: block;
  content: '...\A..\A.';
  white-space: pre-wrap;
  /* 保留空白符序列 */
  animation: span 3s infinite step-start both;
}

@keyframes span {
  33% {
    transform: translateY(-2em);
  }
  66% {
    transform: translateY(-1em);
  }
}

.banner {
  position: relative;
  height: 100vh;
  box-sizing: border-box;
  padding-top: 105px;
}

@media (max-width: 1600px) {
  .banner {
    padding-top: 98px;
  }
}

@media (max-width: 1366px) {
  .banner {
    padding-top: 91px;
  }
}

@media (max-width: 1024px) {
  .banner {
    padding-top: 84px;
  }
}

@media (max-width: 991px) {
  .banner {
    padding-top: 77px;
  }
}

@media (max-width: 767px) {
  .banner {
    padding-top: 70px;
  }
}

.banner #banner-swiper {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.banner #banner-swiper .swiper-slide {
  overflow: hidden;
}

.banner #banner-swiper .swiper-slide.swiper-slide-active .title, .banner #banner-swiper .swiper-slide.swiper-slide-duplicate-active .title {
  opacity: 1;
  transform: translateY(-50%);
}

.banner #banner-swiper .swiper-slide b {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center center;
  background-size: cover;
}

.banner #banner-swiper .swiper-slide .title {
  position: absolute;
  width: fit-content;
  text-align: center;
  top: 50%;
  transform: translateY(-40%);
  right: 0;
  left: 0;
  margin: auto;
  z-index: 2;
  transition: all .5s;
  opacity: 0;
  transition-delay: .8s;
}

.banner #banner-swiper .swiper-slide .title h2 {
  font-size: 60px;
  color: #ffffff;
  line-height: 1.6;
}

@media (max-width: 1600px) {
  .banner #banner-swiper .swiper-slide .title h2 {
    font-size: 51.6px;
  }
}

@media (max-width: 1366px) {
  .banner #banner-swiper .swiper-slide .title h2 {
    font-size: 43.2px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-slide .title h2 {
    font-size: 34.8px;
  }
}

@media (max-width: 991px) {
  .banner #banner-swiper .swiper-slide .title h2 {
    font-size: 26.4px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-slide .title h2 {
    font-size: 18px;
  }
}

.banner #banner-swiper .swiper-slide .title p {
  margin-top: 175px;
  font-size: 20px;
  color: #ffffff;
}

@media (max-width: 1600px) {
  .banner #banner-swiper .swiper-slide .title p {
    margin-top: 146px;
  }
}

@media (max-width: 1366px) {
  .banner #banner-swiper .swiper-slide .title p {
    margin-top: 117px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-slide .title p {
    margin-top: 88px;
  }
}

@media (max-width: 991px) {
  .banner #banner-swiper .swiper-slide .title p {
    margin-top: 59px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-slide .title p {
    margin-top: 30px;
  }
}

@media (max-width: 1600px) {
  .banner #banner-swiper .swiper-slide .title p {
    font-size: 18.8px;
  }
}

@media (max-width: 1366px) {
  .banner #banner-swiper .swiper-slide .title p {
    font-size: 17.6px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-slide .title p {
    font-size: 16.4px;
  }
}

@media (max-width: 991px) {
  .banner #banner-swiper .swiper-slide .title p {
    font-size: 15.2px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-slide .title p {
    font-size: 14px;
  }
}

.banner #banner-swiper .swiper-button-next {
  display: block;
  right: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: #fff;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-button-next {
    display: none;
  }
}

@media (max-width: 1600px) {
  .banner #banner-swiper .swiper-button-next {
    right: 57px;
  }
}

@media (max-width: 1366px) {
  .banner #banner-swiper .swiper-button-next {
    right: 49px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-button-next {
    right: 41px;
  }
}

@media (max-width: 991px) {
  .banner #banner-swiper .swiper-button-next {
    right: 33px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-button-next {
    right: 25px;
  }
}

@media (max-width: 1600px) {
  .banner #banner-swiper .swiper-button-next {
    width: 41.2px;
  }
}

@media (max-width: 1366px) {
  .banner #banner-swiper .swiper-button-next {
    width: 38.4px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-button-next {
    width: 35.6px;
  }
}

@media (max-width: 991px) {
  .banner #banner-swiper .swiper-button-next {
    width: 32.8px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-button-next {
    width: 30px;
  }
}

@media (max-width: 1600px) {
  .banner #banner-swiper .swiper-button-next {
    height: 41.2px;
  }
}

@media (max-width: 1366px) {
  .banner #banner-swiper .swiper-button-next {
    height: 38.4px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-button-next {
    height: 35.6px;
  }
}

@media (max-width: 991px) {
  .banner #banner-swiper .swiper-button-next {
    height: 32.8px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-button-next {
    height: 30px;
  }
}

.banner #banner-swiper .swiper-button-next i {
  font-size: 20px;
  color: #a89cbb;
}

@media (max-width: 1600px) {
  .banner #banner-swiper .swiper-button-next i {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .banner #banner-swiper .swiper-button-next i {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-button-next i {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .banner #banner-swiper .swiper-button-next i {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-button-next i {
    font-size: 16px;
  }
}

.banner #banner-swiper .swiper-button-next:after {
  content: none;
}

.banner #banner-swiper .swiper-button-prev {
  display: block;
  left: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: #fff;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-button-prev {
    display: none;
  }
}

@media (max-width: 1600px) {
  .banner #banner-swiper .swiper-button-prev {
    left: 57px;
  }
}

@media (max-width: 1366px) {
  .banner #banner-swiper .swiper-button-prev {
    left: 49px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-button-prev {
    left: 41px;
  }
}

@media (max-width: 991px) {
  .banner #banner-swiper .swiper-button-prev {
    left: 33px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-button-prev {
    left: 25px;
  }
}

@media (max-width: 1600px) {
  .banner #banner-swiper .swiper-button-prev {
    width: 41.2px;
  }
}

@media (max-width: 1366px) {
  .banner #banner-swiper .swiper-button-prev {
    width: 38.4px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-button-prev {
    width: 35.6px;
  }
}

@media (max-width: 991px) {
  .banner #banner-swiper .swiper-button-prev {
    width: 32.8px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-button-prev {
    width: 30px;
  }
}

@media (max-width: 1600px) {
  .banner #banner-swiper .swiper-button-prev {
    height: 41.2px;
  }
}

@media (max-width: 1366px) {
  .banner #banner-swiper .swiper-button-prev {
    height: 38.4px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-button-prev {
    height: 35.6px;
  }
}

@media (max-width: 991px) {
  .banner #banner-swiper .swiper-button-prev {
    height: 32.8px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-button-prev {
    height: 30px;
  }
}

.banner #banner-swiper .swiper-button-prev:after {
  content: none;
}

.banner #banner-swiper .swiper-button-prev i {
  font-size: 20px;
  color: #a89cbb;
  font-weight: bold;
}

@media (max-width: 1600px) {
  .banner #banner-swiper .swiper-button-prev i {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .banner #banner-swiper .swiper-button-prev i {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-button-prev i {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .banner #banner-swiper .swiper-button-prev i {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-button-prev i {
    font-size: 16px;
  }
}

.banner .yinying {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 100%;
  height: 100%;
}

.section2 {
  padding-top: 80px;
  width: 100%;
  position: relative;
}

@media (max-width: 1600px) {
  .section2 {
    padding-top: 64px;
  }
}

@media (max-width: 1366px) {
  .section2 {
    padding-top: 48px;
  }
}

@media (max-width: 1024px) {
  .section2 {
    padding-top: 32px;
  }
}

@media (max-width: 991px) {
  .section2 {
    padding-top: 16px;
  }
}

@media (max-width: 767px) {
  .section2 {
    padding-top: 0px;
  }
}

.section2 .icons {
  display: block;
  position: absolute;
  right: 40px;
  top: 0;
  transform: translateY(-50%);
  z-index: 10;
}

@media (max-width: 991px) {
  .section2 .icons {
    display: none;
  }
}

.section2 .icons img {
  max-width: fit-content;
  width: 100%;
}

.section2 .container {
  max-width: 920px;
  position: relative;
}

@media (max-width: 991px) {
  .section2 .container {
    max-width: 100%;
  }
}

.section2 .container .semicolon {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 991px) {
  .section2 .container .semicolon {
    display: none;
  }
}

.section2 .container .content {
  display: none;
}

.section2 .container .content .title {
  padding-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 1600px) {
  .section2 .container .content .title {
    padding-top: 20px;
  }
}

@media (max-width: 1366px) {
  .section2 .container .content .title {
    padding-top: 20px;
  }
}

@media (max-width: 1024px) {
  .section2 .container .content .title {
    padding-top: 20px;
  }
}

@media (max-width: 991px) {
  .section2 .container .content .title {
    padding-top: 20px;
  }
}

@media (max-width: 767px) {
  .section2 .container .content .title {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .section2 .container .content .title {
    margin-bottom: 20px;
  }
}

@media (max-width: 1366px) {
  .section2 .container .content .title {
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .section2 .container .content .title {
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .section2 .container .content .title {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .section2 .container .content .title {
    margin-bottom: 20px;
  }
}

.section2 .container .content p {
  font-size: 16px;
  line-height: 2;
  color: #000000;
}

@media (max-width: 1600px) {
  .section2 .container .content p {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .section2 .container .content p {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .section2 .container .content p {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .section2 .container .content p {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .section2 .container .content p {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .section2 .container .content p {
    line-height: 1.92;
  }
}

@media (max-width: 1366px) {
  .section2 .container .content p {
    line-height: 1.84;
  }
}

@media (max-width: 1024px) {
  .section2 .container .content p {
    line-height: 1.76;
  }
}

@media (max-width: 991px) {
  .section2 .container .content p {
    line-height: 1.68;
  }
}

@media (max-width: 767px) {
  .section2 .container .content p {
    line-height: 1.6;
  }
}

.section2 .container .pic {
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1600px) {
  .section2 .container .pic {
    margin-top: 70px;
  }
}

@media (max-width: 1366px) {
  .section2 .container .pic {
    margin-top: 60px;
  }
}

@media (max-width: 1024px) {
  .section2 .container .pic {
    margin-top: 50px;
  }
}

@media (max-width: 991px) {
  .section2 .container .pic {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .section2 .container .pic {
    margin-top: 30px;
  }
}

.section2 .container .pic a {
  position: relative;
  margin-right: 68px;
}

@media (max-width: 1600px) {
  .section2 .container .pic a {
    margin-right: 57.4px;
  }
}

@media (max-width: 1366px) {
  .section2 .container .pic a {
    margin-right: 46.8px;
  }
}

@media (max-width: 1024px) {
  .section2 .container .pic a {
    margin-right: 36.2px;
  }
}

@media (max-width: 991px) {
  .section2 .container .pic a {
    margin-right: 25.6px;
  }
}

@media (max-width: 767px) {
  .section2 .container .pic a {
    margin-right: 15px;
  }
}

.section2 .container .pic a .circular {
  width: 189px;
  height: 189px;
  border-radius: 50%;
  background: #A89CBB;
  position: relative;
}

@media (max-width: 767px) {
  .section2 .container .pic a .circular {
    width: 80px;
  }
}

@media (max-width: 767px) {
  .section2 .container .pic a .circular {
    height: 80px;
  }
}

.section2 .container .pic a:last-child {
  margin-right: 0;
}

.section2 .container .pic a.active img {
  opacity: 1;
}

.section2 .container .pic a.active .circle {
  opacity: 1;
  transform: translate3d(-50%, -50%, 0) scale(1.05);
}

.section2 .container .pic a.active .circle1 {
  opacity: 1;
  transform: translate3d(-50%, -50%, 0) scale(1.1);
}

.section2 .container .pic a img {
  transition: all .3s;
  opacity: .5;
  display: block;
  position: relative;
  z-index: 2;
  height: auto;
  width: fit-content;
}

@media (max-width: 767px) {
  .section2 .container .pic a img {
    width: 80px;
  }
}

.section2 .container .pic a p {
  margin-top: 15px;
  text-align: center;
  font-size: 16px;
  font-family: 'Roboto-Medium';
  color: #000;
}

@media (max-width: 1600px) {
  .section2 .container .pic a p {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .section2 .container .pic a p {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .section2 .container .pic a p {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .section2 .container .pic a p {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .section2 .container .pic a p {
    font-size: 14px;
  }
}

.section2 .container .pic a .circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid rgba(57, 57, 57, 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0;
  transition: all .3s;
}

.section2 .container .pic a .circle1 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid rgba(57, 57, 57, 0.3);
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all .3s;
  opacity: 0;
  transform: translate3d(-50%, -50%, 0);
}

.section2 .pic-bg {
  width: 100%;
  height: 150px;
  background: #EEEEEE;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 150px;
}

@media (max-width: 767px) {
  .section2 .pic-bg {
    height: fit-content;
  }
}

@media (max-width: 767px) {
  .section2 .pic-bg {
    padding-top: 10px;
  }
}

@media (max-width: 767px) {
  .section2 .pic-bg {
    padding-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .section2 .pic-bg {
    margin-top: 20px;
  }
}

.section2 .pic-bg .pic-box {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

@media (max-width: 767px) {
  .section2 .pic-bg .pic-box {
    position: relative;
  }
}

@media (max-width: 767px) {
  .section2 .pic-bg .pic-box {
    transform: none;
  }
}

@media (max-width: 767px) {
  .section2 .pic-bg .pic-box {
    justify-content: space-between;
  }
}

@media (max-width: 767px) {
  .section2 .pic-bg .pic-box {
    flex-wrap: wrap;
  }
}

.section2 .pic-bg .pic-box div {
  margin-right: 40px;
  width: fit-content;
}

@media (max-width: 767px) {
  .section2 .pic-bg .pic-box div {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .section2 .pic-bg .pic-box div {
    width: 18%;
  }
}

.section2 .pic-bg .pic-box img {
  width: 100%;
  display: block;
  height: auto;
  transition: all .3s;
  margin-top: 25px;
}

@media (max-width: 767px) {
  .section2 .pic-bg .pic-box img {
    margin-top: 0;
  }
}

.section2 .pic-bg .pic-box img:hover {
  transform: scale(1.1);
}

@media (max-width: 767px) {
  .section2 .pic-bg .pic-box img:hover {
    transform: scale(1);
  }
}

.section2 .pic-bg .pic-box img:last-child {
  margin-right: 0;
  margin-top: 0;
}

.section3 {
  position: relative;
  z-index: 10;
  margin-bottom: -25px;
  width: 100%;
  background: #A89CBA;
  padding-top: 120px;
  padding-bottom: 105px;
}

@media (max-width: 1600px) {
  .section3 {
    padding-top: 100px;
  }
}

@media (max-width: 1366px) {
  .section3 {
    padding-top: 80px;
  }
}

@media (max-width: 1024px) {
  .section3 {
    padding-top: 60px;
  }
}

@media (max-width: 991px) {
  .section3 {
    padding-top: 40px;
  }
}

@media (max-width: 767px) {
  .section3 {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .section3 {
    padding-bottom: 87px;
  }
}

@media (max-width: 1366px) {
  .section3 {
    padding-bottom: 69px;
  }
}

@media (max-width: 1024px) {
  .section3 {
    padding-bottom: 51px;
  }
}

@media (max-width: 991px) {
  .section3 {
    padding-bottom: 33px;
  }
}

@media (max-width: 767px) {
  .section3 {
    padding-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .section3 {
    padding-left: 10px;
  }
}

@media (max-width: 767px) {
  .section3 {
    padding-right: 10px;
  }
}

.section3 h2 {
  width: 100%;
  text-align: center;
  font-family: 'Roboto-Medium';
  font-size: 40px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 25px;
}

@media (max-width: 1600px) {
  .section3 h2 {
    font-size: 35.6px;
  }
}

@media (max-width: 1366px) {
  .section3 h2 {
    font-size: 31.2px;
  }
}

@media (max-width: 1024px) {
  .section3 h2 {
    font-size: 26.8px;
  }
}

@media (max-width: 991px) {
  .section3 h2 {
    font-size: 22.4px;
  }
}

@media (max-width: 767px) {
  .section3 h2 {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .section3 h2 {
    margin-bottom: 23px;
  }
}

@media (max-width: 1366px) {
  .section3 h2 {
    margin-bottom: 21px;
  }
}

@media (max-width: 1024px) {
  .section3 h2 {
    margin-bottom: 19px;
  }
}

@media (max-width: 991px) {
  .section3 h2 {
    margin-bottom: 17px;
  }
}

@media (max-width: 767px) {
  .section3 h2 {
    margin-bottom: 15px;
  }
}

.section3 h3 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #fff;
  font-weight: normal;
  line-height: 1.8;
}

@media (max-width: 1600px) {
  .section3 h3 {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .section3 h3 {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .section3 h3 {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .section3 h3 {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .section3 h3 {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .section3 h3 {
    line-height: 1.76;
  }
}

@media (max-width: 1366px) {
  .section3 h3 {
    line-height: 1.72;
  }
}

@media (max-width: 1024px) {
  .section3 h3 {
    line-height: 1.68;
  }
}

@media (max-width: 991px) {
  .section3 h3 {
    line-height: 1.64;
  }
}

@media (max-width: 767px) {
  .section3 h3 {
    line-height: 1.6;
  }
}

.section3 .box {
  margin-top: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1600px) {
  .section3 .box {
    margin-top: 60px;
  }
}

@media (max-width: 1366px) {
  .section3 .box {
    margin-top: 50px;
  }
}

@media (max-width: 1024px) {
  .section3 .box {
    margin-top: 40px;
  }
}

@media (max-width: 991px) {
  .section3 .box {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .section3 .box {
    margin-top: 20px;
  }
}

.section3 .box li {
  margin-right: 68px;
}

@media (max-width: 1600px) {
  .section3 .box li {
    margin-right: 56.4px;
  }
}

@media (max-width: 1366px) {
  .section3 .box li {
    margin-right: 44.8px;
  }
}

@media (max-width: 1024px) {
  .section3 .box li {
    margin-right: 33.2px;
  }
}

@media (max-width: 991px) {
  .section3 .box li {
    margin-right: 21.6px;
  }
}

@media (max-width: 767px) {
  .section3 .box li {
    margin-right: 10px;
  }
}

.section3 .box li:last-child {
  margin-right: 0;
}

.section3 .box li .pic {
  width: 147px;
  height: 171px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .section3 .box li .pic {
    width: 90px;
  }
}

@media (max-width: 767px) {
  .section3 .box li .pic {
    height: 104px;
  }
}

.section3 .box li .pic img {
  height: auto;
}

@media (max-width: 767px) {
  .section3 .box li .pic img {
    width: 40px;
  }
}

.section3 .box li p {
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  margin-top: 20px;
}

@media (max-width: 1600px) {
  .section3 .box li p {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .section3 .box li p {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .section3 .box li p {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .section3 .box li p {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .section3 .box li p {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .section3 .box li p {
    margin-top: 18px;
  }
}

@media (max-width: 1366px) {
  .section3 .box li p {
    margin-top: 16px;
  }
}

@media (max-width: 1024px) {
  .section3 .box li p {
    margin-top: 14px;
  }
}

@media (max-width: 991px) {
  .section3 .box li p {
    margin-top: 12px;
  }
}

@media (max-width: 767px) {
  .section3 .box li p {
    margin-top: 10px;
  }
}

.section4 {
  position: relative;
}

@media (max-width: 1024px) {
  .section4 .parallux:nth-child(1) .text h2 {
    display: block;
  }
}

.section4 .text {
  padding-top: 0;
  width: 50%;
  position: absolute;
  position: absolute;
  color: #000;
  height: 100%;
}

@media (max-width: 1024px) {
  .section4 .text {
    padding-top: 20px;
  }
}

@media (max-width: 1024px) {
  .section4 .text {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .section4 .text {
    position: relative;
  }
}

.section4 .text h2 {
  font-family: 'Roboto-Medium';
  font-size: 50px;
  margin-top: 90px;
  margin-left: 94px;
}

@media (max-width: 1024px) {
  .section4 .text h2 {
    display: none;
  }
}

@media (max-width: 1600px) {
  .section4 .text h2 {
    font-size: 45.2px;
  }
}

@media (max-width: 1366px) {
  .section4 .text h2 {
    font-size: 40.4px;
  }
}

@media (max-width: 1024px) {
  .section4 .text h2 {
    font-size: 35.6px;
  }
}

@media (max-width: 991px) {
  .section4 .text h2 {
    font-size: 30.8px;
  }
}

@media (max-width: 767px) {
  .section4 .text h2 {
    font-size: 26px;
  }
}

@media (max-width: 1600px) {
  .section4 .text h2 {
    margin-top: 78px;
  }
}

@media (max-width: 1366px) {
  .section4 .text h2 {
    margin-top: 66px;
  }
}

@media (max-width: 1024px) {
  .section4 .text h2 {
    margin-top: 54px;
  }
}

@media (max-width: 991px) {
  .section4 .text h2 {
    margin-top: 42px;
  }
}

@media (max-width: 767px) {
  .section4 .text h2 {
    margin-top: 30px;
  }
}

@media (max-width: 1600px) {
  .section4 .text h2 {
    margin-left: 81.2px;
  }
}

@media (max-width: 1366px) {
  .section4 .text h2 {
    margin-left: 68.4px;
  }
}

@media (max-width: 1024px) {
  .section4 .text h2 {
    margin-left: 55.6px;
  }
}

@media (max-width: 991px) {
  .section4 .text h2 {
    margin-left: 42.8px;
  }
}

@media (max-width: 767px) {
  .section4 .text h2 {
    margin-left: 30px;
  }
}

.section4 .text p {
  margin-top: 50px;
  text-align: center;
  font-family: 'Roboto-Medium';
  font-size: 26px;
}

@media (max-width: 1600px) {
  .section4 .text p {
    margin-top: 44px;
  }
}

@media (max-width: 1366px) {
  .section4 .text p {
    margin-top: 38px;
  }
}

@media (max-width: 1024px) {
  .section4 .text p {
    margin-top: 32px;
  }
}

@media (max-width: 991px) {
  .section4 .text p {
    margin-top: 26px;
  }
}

@media (max-width: 767px) {
  .section4 .text p {
    margin-top: 20px;
  }
}

@media (max-width: 1600px) {
  .section4 .text p {
    font-size: 24px;
  }
}

@media (max-width: 1366px) {
  .section4 .text p {
    font-size: 22px;
  }
}

@media (max-width: 1024px) {
  .section4 .text p {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .section4 .text p {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .section4 .text p {
    font-size: 16px;
  }
}

.section4 .text img {
  display: block;
  text-align: center;
  margin: auto;
  margin-top: 70px;
  max-width: fit-content;
  width: 100%;
}

@media (max-width: 1600px) {
  .section4 .text img {
    margin-top: 61px;
  }
}

@media (max-width: 1366px) {
  .section4 .text img {
    margin-top: 52px;
  }
}

@media (max-width: 1024px) {
  .section4 .text img {
    margin-top: 43px;
  }
}

@media (max-width: 991px) {
  .section4 .text img {
    margin-top: 34px;
  }
}

@media (max-width: 767px) {
  .section4 .text img {
    margin-top: 25px;
  }
}

@media (max-width: 1600px) {
  .section4 .text img {
    width: 44%;
  }
}

@media (max-width: 1366px) {
  .section4 .text img {
    width: 36%;
  }
}

@media (max-width: 1024px) {
  .section4 .text img {
    width: 70%;
  }
}

.section4 .text .more {
  bottom: 0;
  width: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 146px;
  position: absolute;
}

@media (max-width: 1024px) {
  .section4 .text .more {
    margin-top: 30px;
  }
}

@media (max-width: 1024px) {
  .section4 .text .more {
    height: 120px;
  }
}

@media (max-width: 991px) {
  .section4 .text .more {
    height: 60px;
  }
}

@media (max-width: 1024px) {
  .section4 .text .more {
    position: static;
  }
}

.section4 .text .more a {
  position: relative;
  display: block;
  font-size: 16px;
  transition: all .3s;
}

@media (max-width: 1600px) {
  .section4 .text .more a {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .section4 .text .more a {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .section4 .text .more a {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .section4 .text .more a {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .section4 .text .more a {
    font-size: 14px;
  }
}

.section4 .text .more a:before, .section4 .text .more a:after {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: auto;
  bottom: -5px;
  height: 2px;
  background-color: #000;
  background-color: #000000;
  opacity: 0.3;
}

.section4 .text .more a:after {
  width: 0;
  left: auto;
  right: 0;
  opacity: 1;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: #E7C4CB;
}

.section4 .text .more a:hover:after {
  width: 100%;
  right: auto;
  left: 0;
}

.section4 .parallux {
  overflow: hidden;
}

.section4 .parallux .text.fix {
  position: fixed;
  top: 0;
  transition: all .5s;
  z-index: 1;
}

.section4 .parallux .parallux-bg {
  display: block;
  position: fixed;
  width: 50%;
}

@media (max-width: 1024px) {
  .section4 .parallux .parallux-bg {
    display: none;
  }
}

.section5 {
  margin-left: 65px;
  margin-right: 65px;
  display: flex;
  justify-content: center;
  padding-top: 140px;
}

.section5 .left {
  width: fit-content;
}

@media (max-width: 767px) {
  .section5 .left {
    width: 48%;
  }
}

.section5 .right {
  width: fit-content;
}

@media (max-width: 767px) {
  .section5 .right {
    width: 48%;
  }
}

@media (max-width: 1600px) {
  .section5 {
    margin-left: 54px;
  }
}

@media (max-width: 1366px) {
  .section5 {
    margin-left: 43px;
  }
}

@media (max-width: 1024px) {
  .section5 {
    margin-left: 32px;
  }
}

@media (max-width: 991px) {
  .section5 {
    margin-left: 21px;
  }
}

@media (max-width: 767px) {
  .section5 {
    margin-left: 10px;
  }
}

@media (max-width: 1600px) {
  .section5 {
    margin-right: 54px;
  }
}

@media (max-width: 1366px) {
  .section5 {
    margin-right: 43px;
  }
}

@media (max-width: 1024px) {
  .section5 {
    margin-right: 32px;
  }
}

@media (max-width: 991px) {
  .section5 {
    margin-right: 21px;
  }
}

@media (max-width: 767px) {
  .section5 {
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .section5 {
    justify-content: space-between;
  }
}

@media (max-width: 1600px) {
  .section5 {
    padding-top: 114px;
  }
}

@media (max-width: 1366px) {
  .section5 {
    padding-top: 88px;
  }
}

@media (max-width: 1024px) {
  .section5 {
    padding-top: 62px;
  }
}

@media (max-width: 991px) {
  .section5 {
    padding-top: 36px;
  }
}

@media (max-width: 767px) {
  .section5 {
    padding-top: 10px;
  }
}

.section5 .product {
  display: block;
  position: relative;
  width: fit-content;
  overflow: hidden;
  margin-bottom: 77px;
}

@media (max-width: 1600px) {
  .section5 .product {
    margin-bottom: 63.6px;
  }
}

@media (max-width: 1366px) {
  .section5 .product {
    margin-bottom: 50.2px;
  }
}

@media (max-width: 1024px) {
  .section5 .product {
    margin-bottom: 36.8px;
  }
}

@media (max-width: 991px) {
  .section5 .product {
    margin-bottom: 23.4px;
  }
}

@media (max-width: 767px) {
  .section5 .product {
    margin-bottom: 10px;
  }
}

.section5 .product img {
  transition: all .3s;
  display: block;
  width: 100%;
  max-width: fit-content;
  opacity: .7;
}

.section5 .product:hover img {
  transform: scale(1.1);
}

.section5 .product:hover .text span {
  opacity: 1;
}

.section5 .product:hover .text span:after {
  width: 100%;
}

.section5 .product .product-model {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: -1;
}

.section5 .product .text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

.section5 .product .text h2 {
  font-size: 40px;
  color: #ffffff;
}

@media (max-width: 1600px) {
  .section5 .product .text h2 {
    font-size: 35.6px;
  }
}

@media (max-width: 1366px) {
  .section5 .product .text h2 {
    font-size: 31.2px;
  }
}

@media (max-width: 1024px) {
  .section5 .product .text h2 {
    font-size: 26.8px;
  }
}

@media (max-width: 991px) {
  .section5 .product .text h2 {
    font-size: 22.4px;
  }
}

@media (max-width: 767px) {
  .section5 .product .text h2 {
    font-size: 18px;
  }
}

.section5 .product .text span {
  position: relative;
  font-size: 16px;
  transition: all .3s;
  color: #ffffff;
  opacity: 0;
  margin-top: 35px;
}

@media (max-width: 1600px) {
  .section5 .product .text span {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .section5 .product .text span {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .section5 .product .text span {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .section5 .product .text span {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .section5 .product .text span {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .section5 .product .text span {
    display: none;
  }
}

@media (max-width: 767px) {
  .section5 .product .text span {
    opacity: 1;
  }
}

@media (max-width: 1600px) {
  .section5 .product .text span {
    margin-top: 32px;
  }
}

@media (max-width: 1366px) {
  .section5 .product .text span {
    margin-top: 29px;
  }
}

@media (max-width: 1024px) {
  .section5 .product .text span {
    margin-top: 26px;
  }
}

@media (max-width: 991px) {
  .section5 .product .text span {
    margin-top: 23px;
  }
}

@media (max-width: 767px) {
  .section5 .product .text span {
    margin-top: 20px;
  }
}

.section5 .product .text span:after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background-color: #fff;
  position: absolute;
  bottom: -6px;
  left: 0;
  transition: all .3s;
}

.section5 .right {
  margin-top: 130px;
  margin-left: 60px;
}

@media (max-width: 767px) {
  .section5 .right {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .section5 .right {
    margin-left: 0;
  }
}

.section6 {
  margin-top: 70px;
  display: flex;
  flex-wrap: nowrap;
}

@media (max-width: 1600px) {
  .section6 {
    margin-top: 56px;
  }
}

@media (max-width: 1366px) {
  .section6 {
    margin-top: 42px;
  }
}

@media (max-width: 1024px) {
  .section6 {
    margin-top: 28px;
  }
}

@media (max-width: 991px) {
  .section6 {
    margin-top: 14px;
  }
}

@media (max-width: 767px) {
  .section6 {
    margin-top: 0px;
  }
}

@media (max-width: 991px) {
  .section6 {
    flex-wrap: wrap;
  }
}

.section6 .pic {
  width: 63.23%;
}

@media (max-width: 991px) {
  .section6 .pic {
    width: 100%;
  }
}

.section6 .pic img {
  max-width: 100%;
  width: 100%;
  display: block;
}

.section6 .text {
  width: 100%;
  flex: 1;
  background: #EEEEEE;
  box-sizing: border-box;
  padding-top: 147px;
  padding-left: 94px;
  padding-bottom: 0;
  padding-right: 0;
}

@media (max-width: 1600px) {
  .section6 .text {
    padding-top: 80px;
  }
}

@media (max-width: 1366px) {
  .section6 .text {
    padding-top: 40px;
  }
}

@media (max-width: 1024px) {
  .section6 .text {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .section6 .text {
    padding-left: 50px;
  }
}

@media (max-width: 1366px) {
  .section6 .text {
    padding-left: 40px;
  }
}

@media (max-width: 1024px) {
  .section6 .text {
    padding-left: 20px;
  }
}

@media (max-width: 767px) {
  .section6 .text {
    padding-left: 10px;
  }
}

@media (max-width: 1024px) {
  .section6 .text {
    padding-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .section6 .text {
    padding-right: 20px;
  }
}

@media (max-width: 767px) {
  .section6 .text {
    padding-right: 10px;
  }
}

.section6 .text p {
  margin-bottom: 50px;
  width: 487px;
  font-size: 18px;
  color: #a89cba;
  line-height: 2;
}

@media (max-width: 1600px) {
  .section6 .text p {
    margin-bottom: 30px;
  }
}

@media (max-width: 1366px) {
  .section6 .text p {
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .section6 .text p {
    width: 90%;
  }
}

@media (max-width: 767px) {
  .section6 .text p {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .section6 .text p {
    font-size: 17.4px;
  }
}

@media (max-width: 1366px) {
  .section6 .text p {
    font-size: 16.8px;
  }
}

@media (max-width: 1024px) {
  .section6 .text p {
    font-size: 16.2px;
  }
}

@media (max-width: 991px) {
  .section6 .text p {
    font-size: 15.6px;
  }
}

@media (max-width: 767px) {
  .section6 .text p {
    font-size: 15px;
  }
}

@media (max-width: 1600px) {
  .section6 .text p {
    line-height: 1.92;
  }
}

@media (max-width: 1366px) {
  .section6 .text p {
    line-height: 1.84;
  }
}

@media (max-width: 1024px) {
  .section6 .text p {
    line-height: 1.76;
  }
}

@media (max-width: 991px) {
  .section6 .text p {
    line-height: 1.68;
  }
}

@media (max-width: 767px) {
  .section6 .text p {
    line-height: 1.6;
  }
}

.section6 .text h2 {
  font-family: 'Roboto-Medium';
  color: #a89cba;
  font-size: 32px;
}

@media (max-width: 1600px) {
  .section6 .text h2 {
    font-size: 29.6px;
  }
}

@media (max-width: 1366px) {
  .section6 .text h2 {
    font-size: 27.2px;
  }
}

@media (max-width: 1024px) {
  .section6 .text h2 {
    font-size: 24.8px;
  }
}

@media (max-width: 991px) {
  .section6 .text h2 {
    font-size: 22.4px;
  }
}

@media (max-width: 767px) {
  .section6 .text h2 {
    font-size: 20px;
  }
}
